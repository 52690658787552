import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { theme } from '../../../theme';

import {
  Content,
  FinalCtaSection,
  Heading,
  Input,
  SubHeading,
  Rule,
} from './FinalCta.StyledComponents';

const FinalCtaQuery = graphql`
  query FinalCtaQuery {
    content: contentfulLandingPageV2 {
      ...FinalCtaContent
    }
  }
`;

const FinalCta = ({ viewHeight }) => {
  const { content } = useStaticQuery(FinalCtaQuery);
  const [searchValue, setSearchValue] = React.useState('');
  const [screenXs, setScreenXs] = React.useState(true);

  // Use viewHeight to trigger a recalc of matchMedia
  // So we're not re-rendering too much in LandingPage
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setScreenXs(window.matchMedia(theme.breakpoints.sm).matches);
    }
  }, [viewHeight]);

  const searchOnChange = e => {
    setSearchValue(e.target.value);
  };

  const executeSearch = () => {
    window.location = `/domains/${searchValue}`;
  };

  const searchOnKeyDown = e => {
    if (e.key === 'Enter') executeSearch();
  };

  const searchOnFocus = _ => {
    const inputElement = document.getElementById('tldSearch');
    inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <FinalCtaSection>
      <Content>
        <Heading>{content.finalCtaLine1}</Heading>
        <SubHeading>{content.finalCtaLine2}</SubHeading>
        <div css={{ height: '1px', width: '1px', overflow: 'hidden' }}>
          <label aria-label="Search for your personal TLD" htmlFor="tldSearch">
            Search for your personal TLD
          </label>
        </div>
        <div css={{ display: 'flex', alignItems: 'baseline' }}>
          <Input
            onFocus={searchOnFocus}
            onKeyDown={searchOnKeyDown}
            onChange={searchOnChange}
            value={searchValue}
            id="tldSearch"
            placeholder={screenXs ? 'Your personal TLD' : 'Search for your personal TLD'}
          />
          <button
            onClick={executeSearch}
            aria-label={'Search TLD button'}
            css={{ height: '100%', width: '56px' }}>
            <SearchIcon />
          </button>
        </div>
        <Rule />
      </Content>
    </FinalCtaSection>
  );
};

const SearchIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      className="searchStroke"
      cx="6.5"
      cy="6.5"
      r="5.625"
      stroke="#0068FF"
      strokeWidth="1.75"
    />
    <path className="searchStroke" d="M11 11L15 15" stroke="#0068FF" strokeWidth="1.75" />
  </svg>
);

export { FinalCta };
