import styled from 'styled-components';

export const PickAnyNameSection = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.color.almostBlack};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: flex-start;

  padding: ${({ theme: { spacing } }) => `${spacing(21)} ${spacing(20)}`};

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing(17)};
    padding-bottom: ${({ theme }) => theme.spacing(17)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: ${({ theme: { spacing } }) => `${spacing(13)} ${spacing(8.5)}`};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const HeadingWrapper = styled.div`
  opacity: ${({ animate }) => (animate ? '1' : '0')};
  transform: ${({ animate }) => (animate ? 'translateY(0)' : 'translateY(15%)')};
  transition: all 0.45s ease-out;
  transition-delay: ${({ animate }) => (animate ? '0s' : '1s')};

  * {
    opacity: ${({ animate }) => (animate ? '1' : '0')};
    transform: ${({ animate }) => (animate ? 'translateY(0)' : 'translateY(15%)')};
    transition: all 0.45s ease-out;
    transition-delay: ${({ animate }) => (animate ? '0s' : '1s')};
  }

  li:nth-of-type(1) {
    transition-delay: 0.3s;
  }
  li:nth-of-type(2) {
    transition-delay: 0.4s;
  }
  li:nth-of-type(3) {
    transition-delay: 0.5s;
  }
  li:nth-of-type(4) {
    transition-delay: 0.6s;
  }
`;

export const Heading = styled.h2`
  width: ${({ theme }) => theme.spacing(55)};
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;

  i {
    font-weight: normal;
  }

  margin-right: ${({ theme }) => theme.spacing(15.125)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 100%;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.displayLarge.size};
    line-height: ${({ theme }) => theme.text.displayLarge.lineHeight};
  }
`;

export const Examples = styled.div`
  font-weight: 700;
  font-size: ${({ theme }) => theme.text.displayTiny.size};
  line-height: ${({ theme }) => theme.text.displayTiny.lineHeight};

  margin-bottom: 56px;
  & * + * {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }

  /* Specific margins for emoji size optical illusion */
  li:nth-of-type(3) {
    margin-top: 32px;
  }
  li:last-of-type {
    margin-top: 20px;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.headerLarge.size};
    line-height: ${({ theme }) => theme.text.headerLarge.lineHeight};
  }
`;

export const Elaboration = styled.div`
  width: ${({ theme }) => theme.spacing(69.375)};

  * {
    font-size: ${({ theme }) => theme.text.header.size};
    line-height: 36px;

    @media ${({ theme }) => theme.breakpoints.sm} {
      font-size: ${({ theme }) => theme.text.large.size};
      line-height: ${({ theme }) => theme.text.large.lineHeight};
    }
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 70%;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
  }
`;
