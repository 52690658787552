import Img from 'gatsby-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Galleries, Gallery, GalleryItem } from './BackersAndAdopters.StyledComponents';

const backersAndAdoptersQuery = graphql`
  query BackersAndAdoptersQuery {
    images: allContentfulHandshakeBackersAndAdopters {
      ...BackersAndAdoptersImages
    }
    content: contentfulLandingPageV3 {
      ...BackersAndAdoptersContent
    }
  }
`;

const BackersAndAdopters = () => {
  const { images, content } = useStaticQuery(backersAndAdoptersQuery);

  const backers = images.asArray.filter(image => image.type === 'backer').reverse();
  const adopters = images.asArray.filter(image => image.type === 'adopter').reverse();

  // Move Epik to end, for aesthetics
  const indexOfEpik = adopters.findIndex(({ logo }) => logo.title.toLowerCase().includes('epik'));
  adopters.push(adopters.splice(indexOfEpik, 1)[0]);

  return (
    <Galleries>
      {/*<Gallery heading={content.investorsHeading}>*/}
      {/*  {backers.map(backer => (*/}
      {/*    <GalleryItem key={backer.id} title={backer.logo.title}>*/}
      {/*      <Img alt={backer.logo.title} fluid={backer.logo.fluid} />*/}
      {/*    </GalleryItem>*/}
      {/*  ))}*/}
      {/*</Gallery>*/}
      <Gallery heading={content.adoptersHeading}>
        {adopters.map(adopter => (
          <GalleryItem key={adopter.id} title={adopter.logo.title}>
            <Img alt={adopter.logo.title} fluid={adopter.logo.fluid} />
          </GalleryItem>
        ))}
      </Gallery>
    </Galleries>
  );
};

export { BackersAndAdopters };
