import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import {
  Elaboration,
  Examples,
  Heading,
  HeadingWrapper,
  PickAnyNameSection,
} from './PickAnyName.StyledComponents';

const PickAnyNameQuery = graphql`
  query PickAnyNameQuery {
    content: contentfulLandingPageV2 {
      ...PickAnyNameContent
    }
  }
`;

const PickAnyName = () => {
  const { content } = useStaticQuery(PickAnyNameQuery);

  const animationTrigger = React.useRef(null);
  const [animateLoading, setAnimateLoading] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!animationTrigger || !animationTrigger.current) {
      throw new Error('animationTrigger ref not registered');
    }

    const observer = new IntersectionObserver(
      (observerEntries, _) => setAnimateLoading(observerEntries[0].isIntersecting),
      { rootMargin: '0px 0px -15% 0px' }
    );

    observer.observe(animationTrigger.current);
  }, [animationTrigger]);

  return (
    <PickAnyNameSection>
      <HeadingWrapper ref={animationTrigger} animate={animateLoading}>
        <Heading>{documentToReactComponents(content.pickAnyNameCallout.json)}</Heading>
        <Examples>{documentToReactComponents(content.pickAnyNameExamples.json)}</Examples>
      </HeadingWrapper>
      <Elaboration>
        <p css={{ marginBottom: '36px' }}>{content.pickAnyNameElaborationLine1}</p>
        <p>{content.pickAnyNameElaborationLine2}</p>
      </Elaboration>
    </PickAnyNameSection>
  );
};

export { PickAnyName };
