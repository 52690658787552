import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { stringWithDollarAmounts } from '../../StatsPage/formatters';
import { Stack, HandshakeLogo, H2, Figure } from './DecentralizedDns.StyledComponents';

const decentralizedDnsQuery = graphql`
  query DecentralizedDnsQuery {
    content: contentfulLandingPageV3 {
      dnsIndustryOverline
    }
  }
`;

const DecentralizedDns = () => {
  const { content } = useStaticQuery(decentralizedDnsQuery);

  return (
    <Stack>
      <HandshakeLogo />
      <H2>{stringWithDollarAmounts(content.dnsIndustryOverline)}</H2>
      <Figure />
    </Stack>
  );
};

export { DecentralizedDns };
