import styled, { keyframes, css } from 'styled-components';

const shrinkBorderBaseStyles = css`
  position: absolute;
  background-color: ${({ theme }) => theme.color.white};
  transition: transform 300ms ease-out;

  --grow-border-base-size: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    --grow-border-base-size: ${({ theme }) => theme.spacing(3)};
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    --grow-border-base-size: ${({ theme }) => theme.spacing(1.5)};
  }

  ${({ isWebkit }) => (isWebkit ? turbochargeWebkit : null)}
`;

const shrinkBorderLeftRightStyles = css`
  ${shrinkBorderBaseStyles}
  height: 100%;
  width: var(--grow-border-base-size);
`;

const shrinkBorderTopBottomStyles = css`
  ${shrinkBorderBaseStyles}
  width: 100%;
  height: var(--grow-border-base-size);
`;

export const ShrinkBorderTop = styled.div`
  top: 0;
  transform-origin: center top;
  ${shrinkBorderTopBottomStyles}
`;

export const ShrinkBorderBottom = styled.div`
  bottom: 0;
  transform-origin: center bottom;
  ${shrinkBorderTopBottomStyles}
`;

export const ShrinkBorderLeft = styled.div`
  left: 0;
  transform-origin: left center;
  ${shrinkBorderLeftRightStyles}
`;

export const ShrinkBorderRight = styled.div`
  right: 0;
  transform-origin: right center;
  ${shrinkBorderLeftRightStyles}
`;

export const DramaticCalloutBackdrop = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.almostBlack};
  margin-bottom: ${({ theme }) => theme.spacing(42)};
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const fontGrowAnimation = keyframes`
  0% {
    font-variation-settings: 'wght' 400;
    letter-spacing: normal;
  }
  100% {
    font-variation-settings: 'wght' 800;
    letter-spacing: 0.5px;
  }
`;

const fontShrinkAnimation = keyframes`
  0% {
    font-variation-settings: 'wght' 800;
    letter-spacing: 0.5px;
  }
  100% {
    font-variation-settings: 'wght' 400;
    letter-spacing: normal;
  }
`;

export const DramaticCalloutLine2 = styled.div`
  font-family: 'Roboto Variable', Roboto;
  animation: ${({ animate, reverseAnimate }) => {
    const playState = animate || reverseAnimate ? 'running' : 'paused';
    const keyframes = reverseAnimate ? fontShrinkAnimation : fontGrowAnimation;
    return css`0.8s ease-in-out 0.25s 1 normal both ${playState} ${keyframes}`;
  }};
`;

export const ContentArea = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Callout = styled.h1`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  font-size: 64px;
  line-height: 72px;

  opacity: ${({ variableFontsReady }) => (variableFontsReady ? '1' : '0')};
  transition: opacity 0.125s ease-out;

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 56px;
    line-height: 64px;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.displayTiny.size};
    line-height: ${({ theme }) => theme.text.displayTiny.lineHeight};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    font-size: ${({ theme }) => theme.text.headerSmall.size};
    line-height: ${({ theme }) => theme.text.headerSmall.lineHeight};
  }
`;

/**
 * turbochargeWebkit
 *
 * Implements some Webkit/Safari specific performance boosts.
 *
 * FAQ:
 * Why not just enable these in all browsers?
 * Note here https://csstriggers.com/ that only WebKit
 * triggers a relayout on the transform value change. Hence we
 * only add this stuff in an attemp to promote the borders to their
 * own layer so as to mitigate their effect on other layers. Adding
 * them for other browsers is unnecessary and could cause worse
 * performance.
 */
const turbochargeWebkit = css`
  will-change: transform;
  transform-style: preserve-3d;
  backface-visibility: none;
`;
