import styled from 'styled-components';

export const WhyHandshakeSection = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.color.grey10};
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme: { spacing } }) => `${spacing(17)} ${spacing(12)}`};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-left: ${({ theme }) => theme.spacing(10)};
    padding-right: ${({ theme }) => theme.spacing(10)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${({ theme }) => theme.spacing(11)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Heading = styled.h2`
  color: ${({ theme }) => theme.color.nbPrimary};
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  font-family: 'Roboto Variable', Roboto;
  letter-spacing: 1.5px;
  font-variation-settings: 'wght' 300;
`;

export const GridArea = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(11)};

  display: grid;
  column-gap: ${({ theme }) => theme.spacing(13)};
  row-gap: ${({ theme }) => theme.spacing(11)};
  grid-template-areas:
    'item1 item2 item3'
    'item4 item5 item6';

  @media ${({ theme }) => theme.breakpoints.md} {
    column-gap: ${({ theme }) => theme.spacing(9)};
    row-gap: ${({ theme }) => theme.spacing(8)};
    grid-template-areas:
      'item1 item2'
      'item3 item4'
      'item5 item6';
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-areas:
      'item1'
      'item2'
      'item3'
      'item4'
      'item5'
      'item6';
  }
`;

export const GridItem = styled.div`
  width: 100%;
  & * + * {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export const GridItemHeader = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: 'Roboto Mono';
  line-height: 32px;
  color: ${({ theme }) => theme.color.nbPrimary};
`;

export const GridItemDescription = styled.div`
  font-size: ${({ theme }) => theme.text.regular.size};
  line-height: ${({ theme }) => theme.text.regular.lineHeight};
  color: ${({ theme }) => theme.color.brandDark};

  @media ${({ theme }) => theme.breakpoints.sm} {
    line-height: 28px;
    font-size: ${({ theme }) => theme.text.medium.size};
    line-height: ${({ theme }) => theme.text.medium.lineHeight};
  }
`;
