import styled from 'styled-components';

export const WhatToDoWithYourNameSection = styled.section`
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  font-family: 'Roboto Variable';
  padding: ${({ theme }) => theme.spacing(13)} ${({ theme }) => theme.spacing(6)};
  background-color: ${({ theme }) => theme.color.almostBlack};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(3)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Heading = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
`;

export const HeadingText = styled.h2`
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 1.5px;
  font-variation-settings: 'wght' 300;

  b {
    font-variation-settings: 'wght' 400;
  }
`;

export const UsecaseList = styled.ul`
  margin-top: ${({ theme }) => theme.spacing(8)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  & > * {
    opacity: ${({ animate }) => (animate ? '1' : '0')};
    transform: ${({ animate }) => (animate ? 'translateY(0)' : 'translateY(15%)')};
    transition: all 0.45s ease-out;
    transition-delay: ${({ animate }) => (animate ? '0s' : '1s')};
    border-bottom: 1px solid ${({ theme }) => theme.color.white};
  }

  li:nth-of-type(1) {
    transition-delay: 0.3s;
  }
  li:nth-of-type(2) {
    transition-delay: 0.4s;
  }
  li:nth-of-type(3) {
    transition-delay: 0.5s;
  }
  li:nth-of-type(4) {
    transition-delay: 0.6s;
  }
`;

export const UsecaseListItemImage = styled.div`
  width: ${({ theme }) => theme.spacing(7)};

  @media ${({ theme }) => theme.breakpoints.md} {
    width: ${({ theme }) => theme.spacing(5)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;

export const UsecaseListItem = styled.li`
  display: flex;
  font-size: 46px;
  line-height: 56px;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  font-variation-settings: 'wght' 300;

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }

  a:hover {
    text-decoration: underline;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 28px;
    line-height: 40px;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 24px;
    & > * + * {
      margin-left: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export const ShowcaseCta = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.text.medium.size};
  line-height: ${({ theme }) => theme.text.medium.lineHeight};
  font-variation-settings: 'wght' 700;

  & > * {
    width: max-content;
  }

  path {
    stroke: ${({ theme }) => theme.color.white};
  }
`;
