import styled, { css } from 'styled-components';

import { Large } from '../../text';
import { Steps } from '../../LandingPage/HowItWorks/HowItWorks.StyledComponents';
import {Cta} from "../LandingPage.StyledComponents";

export const H2 = styled(Large).attrs(props => ({
  as: 'h2',
  align: 'center',
  weight: 'bold',
  colored: 'nbPrimary',
  transform: 'uppercase',
}))`
  //margin-top: 156px;
  font-size: ${({ theme }) => theme.text.huge.size};

  @media ${({ theme }) => theme.breakpoints.md} {
    line-height: ${({ theme }) => theme.text.huge.lineHeight};
  }
`;

const largePaddingCss = css`
  padding-left: 24px;
  padding-right: 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-left: 48px;
    padding-right: 48px;
  }
  
  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const DescriptionList = styled(Steps).attrs(props => ({ as: 'dl' }))`
  ${largePaddingCss};

  //& > * {
  //  max-width: 316px;
  //}
`;

export const DescriptionTerm = styled.dt`
  span {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
`;

export const StyledCta = styled(Cta)`
  padding: 0;
  margin-left: 8px;
  ${largePaddingCss};

  a {
    margin: 30px auto;
    & > div {
      font-weight: 500;
      font-size: 28px;
      @media ${({ theme }) => theme.breakpoints.md} {
        font-size: 26px;
      }
    }
  }

  a svg {
    margin-left: 4px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: ${({ theme }) => theme.text.regular.size};
    line-height: ${({ theme }) => theme.text.regular.lineHeight};

    a {
      margin: 40px auto;
    }
    svg {
      width: 32px;
    }
  }
`;

export const ImageSize = styled.div`
  width: 100%;
  height: 325px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 30vw;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 40vw;
    transform: scale(1);
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    height: auto;
    transform: scale(1.25);
  }
`;

export const ImagePosition = styled.div`
  width: 750px;
  z-index: -1;
  transform: translate(0%, -60px);

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 75%;
    transform: translate(10%, -60px);
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 85%;
    transform: translate(72px, -40px);
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    transform: translate(16px, -12px);
  }
`;
