import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CtaAnchor, CenterAlign, FrameWithMargin } from './Marketplace.StyledComponents';

const marketplaceQuery = graphql`
  query MarketplaceQuery {
    content: contentfulLandingPageV3 {
      finalCtaHref
      finalCtaLabel
    }
  }
`;

const Marketplace = () => {
  const { content } = useStaticQuery(marketplaceQuery);

  return (
    <FrameWithMargin>
      <CenterAlign>
        <CtaAnchor href={content.finalCtaHref}>{content.finalCtaLabel}</CtaAnchor>
      </CenterAlign>
    </FrameWithMargin>
  );
};

export { Marketplace };
