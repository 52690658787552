import React from 'react';
import styled, { css } from 'styled-components';

import { Huge, Tiny, Small } from '../../text';
import {Cta} from "../LandingPage.StyledComponents";

export const FramePadding = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-left: ${({ theme }) => theme.spacing(12.5)};
  padding-right: ${({ theme }) => theme.spacing(12.5)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
  }
`;

export const Controls = styled.div`
  width: max-content;
  margin: 0 auto;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-bottom: 0;

    a,
    div:last-of-type {
      display: none;
    }
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const ControlButton = styled(Small).attrs(props => ({ as: 'button', weight: 'medium' }))`
  width: max-content;
  color: ${({ theme, isActive }) => theme.color[isActive ? 'nbPrimary' : 'brandDark']};
  padding: 0 ${({ theme }) => theme.spacing(3)};

  &:hover {
    color: ${({ theme }) => theme.color.nbPrimary};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 ${({ theme }) => theme.spacing(1.5)};
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    padding: 0;
  }
`;

export const ControlButtonSm = styled(ControlButton)`
  display: none;

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin: ${({ theme }) => theme.spacing(4)} auto;
    display: block;
    padding-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ControlDivider = styled.div.attrs(props => ({ role: 'presentation' }))`
  width: 1px;
  height: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.color.brandDark};
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.color.grey30};

  @media ${({ theme }) => theme.breakpoints.sm} {
    td:nth-child(-n + 2) > div {
      padding-bottom: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

const cellWidthCss = css`
  width: ${({ lg = 'auto' }) => lg};

  @media ${({ theme }) => theme.breakpoints.md} {
    width: ${({ md = 'auto' }) => md};
    display: ${({ md = 'auto' }) => (md === '0%' ? 'none' : null)};
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: ${({ sm = 'auto' }) => sm};
    display: ${({ sm = 'auto' }) => (sm === '0%' ? 'none' : null)};
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    width: ${({ xs = 'auto' }) => xs};
    display: ${({ xs = 'auto' }) => (xs === '0%' ? 'none' : null)};
  }
`;

export const TableHead = styled.th.attrs(props => ({
  children: (
    <Tiny align="right" weight="bold" colored="nbPrimary">
      {props.children}
    </Tiny>
  ),
}))`
  ${cellWidthCss};
  opacity: ${({ opacity = 1 }) => opacity};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const TableCell = styled.td.attrs(props => ({
  children: (
    <Huge {...props} as="div">
      {props.children}
    </Huge>
  ),
}))`
  ${cellWidthCss};
  height: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.breakpoints.md} {
    ${Huge} {
      font-size: ${({ theme }) => theme.text.large.size};
      line-height: ${({ theme }) => theme.text.large.lineHeight};
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: ${({ theme }) => theme.spacing(9)};

    ${Huge} {
      font-size: ${({ theme }) => theme.text.small.size};
      line-height: ${({ theme }) => theme.text.small.lineHeight};
    }
  }
`;

export const SaleDetails = styled.div.attrs(props => ({
  children: (
    <React.Fragment>
      <div>{props.soldFor}</div>
    </React.Fragment>
  ),
}))`
  .gain {
    color: ${({ theme }) => theme.color.green};
    display: none;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    .gain {
      display: block;
    }
  }
`;

const CtaBase = styled(Cta)`
  padding: 0;
  margin-top: 0;

  a {
    margin: ${({ theme }) => theme.spacing(3)} auto;
    margin-bottom: 0;
  }
`;

export const CtaLg = styled(CtaBase)`
  display: flex;
  a > div {
    font-size: 23px;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;

export const CtaSm = styled(CtaBase)`
  display: none;

  * {
    font-size: ${({ theme }) => theme.text.small.size};
    line-height: ${({ theme }) => theme.text.small.lineHeight};
  }
  svg {
    width: ${({ theme }) => theme.spacing(4)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: flex;
  }
`;

export const DomainNameAnchor = styled.a`
  color: ${({ theme }) => theme.color.brandDark};
  transition: ${({ theme }) => theme.transitions.color};

  &:hover {
    color: ${({ theme }) => theme.color.nbPrimary};
  }
`;
