import React from 'react';

import { LandingPage } from '../components/LandingPageV2';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

const IndexPage = () => {
  // Used by LandingPage to control position of the navbar based on page scroll
  const navbarRef = React.useRef(null);
  const bannerRef = React.useRef(null);

  const meta = usePageMetaAttributes('index');
  const [scrolledToTop, setScrolledToTop] = React.useState(true);

  // CAUTION: Used to trigger re-render of the landing page so that bannerRef updates
  const [announcementDismissed, setAnnouncementDismissed] = React.useState(false);

  const onAnnouncementDismiss = () => {
    setAnnouncementDismissed(true);
  };

  return (
    <Layout
      noFooter // Footer rendered by LandingPage component
      navbarRef={navbarRef}
      bannerRef={bannerRef}
      onAnnouncementDismiss={onAnnouncementDismiss}
      onScrolledToTopChange={setScrolledToTop}>
      <SEO {...meta} />
      <LandingPage
        bannerRef={bannerRef}
        navbarRef={navbarRef}
        scrolledToTop={scrolledToTop}
        bannerDismissed={announcementDismissed}
      />
    </Layout>
  );
};

export default IndexPage;
