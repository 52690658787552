import styled from 'styled-components';
import { Large } from '../../text';
import { Link } from '../Link';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacing(7)};
  padding-left: ${({ theme }) => theme.spacing(12)};
  padding-right: ${({ theme }) => theme.spacing(12)};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-left: ${({ theme }) => theme.spacing(10)};
    padding-right: ${({ theme }) => theme.spacing(10)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${({ theme }) => theme.spacing(11)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Heading = styled.h2`
  color: ${({ theme }) => theme.color.nbPrimary};
  font-size: 40px;
  font-weight: 300;
  font-family: 'Roboto Variable', Roboto;
  line-height: 48px;
  letter-spacing: 1.5px;
  font-variation-settings: 'wght' 300;
`;

export const Steps = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.breakpoints.md} {
    margin-top: ${({ theme }) => theme.spacing(6)};
    flex-direction: column;
    align-items: center;
    & > * + * {
      margin-top: ${({ theme }) => theme.spacing(6)};
    }
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${({ theme }) => theme.spacing(43.375)};

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: ${({ theme }) => theme.spacing(48.75)};
  }

  // @media ${({ theme }) => theme.breakpoints.sm} {
  //   max-width: ${({ theme }) => theme.spacing(59.375)};
  // }

  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 100%;
  }
`;

export const StepNumber = styled.div`
  font-family: 'Roboto Mono';
  color: ${({ theme }) => theme.color.nbPrimary};
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StepTitle = styled(Large).attrs({ as: 'h3', weight: 'bold' })``;

export const StepDescription = styled.div`
  font-family: Roboto;
  font-size: ${({ theme }) => theme.text.regular.size};
  line-height: ${({ theme }) => theme.text.regular.lineHeight};
  text-align: center;
  color: ${({ theme }) => theme.color.brandDark};

  margin-top: ${({ theme }) => theme.spacing(1)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.medium.size};
    line-height: ${({ theme }) => theme.text.medium.lineHeight};
  }
`;

export const PhoneImage = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0.25vw;
  right: 1px;
`;

export const PhoneImageBreakpoints = styled.div`
  max-width: 734px;
  .gatsby-image-wrapper {
    width: 734px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 568px;
    .gatsby-image-wrapper {
      width: 568px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 375px;
    .gatsby-image-wrapper {
      width: 375px;
    }
  }
`;

export const LearnMoreLink = styled(Link)`
  width: max-content;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(17)};
  margin-bottom: ${({ theme }) => theme.spacing(17)};
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.md} {
    margin-top: ${({ theme }) => theme.spacing(10)};
    margin-bottom: ${({ theme }) => theme.spacing(19)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-bottom: ${({ theme }) => theme.spacing(14)};
  }

  & a {
    color: ${({ theme }) => theme.color.nbPrimary};
  }

  &:hover {
    & * {
      stroke: ${({ theme }) => theme.color.nbPrimaryDark};
      color: ${({ theme }) => theme.color.nbPrimaryDark};
    }
  }
`;
