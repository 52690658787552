import styled from 'styled-components';

export const OldInternetBadSection = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.color.nbPrimary};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: flex-start;

  padding-top: ${({ theme }) => theme.spacing(21)};
  padding-bottom: ${({ theme }) => theme.spacing(21)};
  padding-left: ${({ theme }) => theme.spacing(20)};

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing(17)};
    padding-bottom: ${({ theme }) => theme.spacing(17)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${({ theme }) => theme.spacing(13)};
    padding-bottom: ${({ theme }) => theme.spacing(13)};
    padding-left: ${({ theme }) => theme.spacing(8.5)};
    padding-right: ${({ theme }) => theme.spacing(8.5)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Heading = styled.h2`
  width: ${({ theme }) => theme.spacing(55)};
  margin-right: ${({ theme }) => theme.spacing(15.125)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;

  opacity: ${({ animate }) => (animate ? '1' : '0')};
  transform: ${({ animate }) => (animate ? 'translateY(0px)' : 'translateY(15%)')};
  transition: all 0.45s ease-out ${({ animate }) => (animate ? '0s' : '1s')};

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 70%;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    font-size: ${({ theme }) => theme.text.displayLarge.size};
    line-height: ${({ theme }) => theme.text.displayLarge.lineHeight};
  }
`;

export const Elaboration = styled.div`
  width: ${({ theme }) => theme.spacing(69.375)};
  * {
    font-size: ${({ theme }) => theme.text.header.size};
    line-height: 36px;
  }

  & > * + * {
    margin-top: 36px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 70%;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    * {
      font-size: ${({ theme }) => theme.text.large.size};
      line-height: ${({ theme }) => theme.text.large.lineHeight};
    }
  }
`;
