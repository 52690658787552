import styled from 'styled-components';
import { Link } from '../Link';

export const TheMarketplaceSection = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.color.grey10};

  padding: ${({ theme: { spacing } }) => `${spacing(12)} ${spacing(20)}`};

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: column-reverse;
    padding-bottom: ${({ theme }) => theme.spacing(17)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: ${({ theme: { spacing } }) => `${spacing(13)} ${spacing(8.5)}`};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Content = styled.div`
  width: 50%;
  margin-bottom: ${({ theme }) => theme.spacing(12)};
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 70%;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
  }
`;

export const Heading = styled.h3`
  color: ${({ theme }) => theme.color.brandDark};
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.displaySmall.size};
    line-height: ${({ theme }) => theme.text.displaySmall.lineHeight};
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.color.brandDark};
  font-size: ${({ theme }) => theme.text.huge.size};
  line-height: ${({ theme }) => theme.text.huge.lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.large.size};
    line-height: ${({ theme }) => theme.text.large.lineHeight};
  }
`;

export const KeyPoints = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.spacing(3)};
  row-gap: ${({ theme }) => theme.spacing(3)};
  grid-template-columns: min-content auto;
  grid-template-areas:
    'marker1 item1'
    'marker2 item2';
`;

export const KeyPointsItem = styled.div`
  color: ${({ theme }) => theme.color.brandDark};
  font-size: ${({ theme }) => theme.text.medium.size};
  line-height: ${({ theme }) => theme.text.medium.lineHeight};
`;

export const Marker = styled.hr`
  border: 0;
  margin-top: 1.33rem;
  width: ${({ theme }) => theme.spacing(4)};
  max-width: ${({ theme }) => theme.spacing(4)};
  height: 1px;
  background: linear-gradient(177.14deg, #ff5980 1.46%, #ffa882 98.22%);
`;

export const Graphic = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4.125)};
  @media ${({ theme }) => theme.breakpoints.md} {
    margin-top: ${({ theme }) => theme.spacing(8.25)};
  }
`;

export const ExploreMarketLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing(8)};
  justify-content: flex-start;

  @media ${({ theme }) => theme.breakpoints.sm} {
    justify-content: center;
  }

  & * {
    color: ${({ theme }) => theme.color.brandDark};
    stroke: ${({ theme }) => theme.color.brandDark};
  }
`;
