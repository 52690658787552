import styled from 'styled-components';

export const BannerSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.text.regular.size};
  background-color: ${({ theme }) => theme.color.nbBlue300};
  color: ${({ theme }) => theme.color.brandDark};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(1)}`};

  a {
    color: ${({ theme }) => theme.color.nbPrimary};
    text-decoration: underline;
  }

  a:hover,
  a:focus {
    color: ${({ theme }) => theme.color.nbPrimaryDark};
    text-decoration: none;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(0.5)};
  }
`;
