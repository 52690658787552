import Img from 'gatsby-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  H1,
  H2,
  Input,
  CtaForm,
  CtaContent,
  IphoneImage,
  AlignContent,
  BackgroundSvg,
  WidthAndHeight, SearchInput, SearchIcon, HeroTlds, HeroTld,
} from './Hero.StyledComponents';
import { Button } from '../../Button';
import { OutlinedInputWrapper } from "../../../packages/NamebaseUi/components/Form/TextInput.StyledComponents";

const HeroQuery = graphql`
  query HeroQuery {
    content: contentfulLandingPageV3 {
      ...HeroContent
    }
  }
`;

const Hero = () => {
  const { content } = useStaticQuery(HeroQuery);
  const [formValue, setFormValue] = React.useState('');

  const onFormSubmit = e => {
    e.preventDefault();
    const encodedFormValue = encodeURIComponent(formValue);
    window.location.href = `/domains?q=${encodedFormValue}`;
  };

  return (
    <WidthAndHeight>
      <CtaContent>
        <H1 weight="bold" colored="nbPrimary">Own .yourname/ on&nbsp;the&nbsp;Internet</H1>
        <H2>We make it easy to buy, sell, and use&nbsp;Handshake&nbsp;domains.</H2>
        <CtaForm method="get" onSubmit={onFormSubmit}>
          <OutlinedInputWrapper>
            <SearchInput
              type="text"
              value={formValue}
              onChange={e => setFormValue(e.target.value)}
              autoFocus
              placeholder="Find your domain">
            </SearchInput>
            <span onClick={onFormSubmit} style={{
              right: '0',
              height: '100%',
              padding: '0 14px',
              display: 'inline-flex',
              position: 'absolute',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
              <SearchIcon />
            </span>
          </OutlinedInputWrapper>
          {/*<Button size="large" type="submit" style={{ 'height': '48px' }}>*/}
          {/*  <span style={{ fontSize: '17px' }}>Search</span>*/}
          {/*</Button>*/}
        </CtaForm>
        <HeroTlds>
          <HeroTld>.hns</HeroTld>
          <HeroTld>.🤝</HeroTld>
          <HeroTld>.你好</HeroTld>
          <HeroTld>.yourbrand</HeroTld>
          <HeroTld>.identity</HeroTld>
          <HeroTld>.🚀</HeroTld>
          <HeroTld>.hub</HeroTld>
        </HeroTlds>
      </CtaContent>
    </WidthAndHeight>
  );
};

export { Hero };
