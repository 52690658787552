import React from 'react';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';

import {
  Table,
  CtaSm,
  CtaLg,
  Controls,
  TableRow,
  TableHead,
  TableCell,
  SaleDetails,
  FramePadding,
  ControlButton,
  ControlDivider,
  ControlButtonSm,
  DomainNameAnchor,
} from './TopSales.StyledComponents';
import { Large } from '../../text';
import { Frame } from '../LandingPage.StyledComponents';
import { domainNameWithPunycode, localeStringDefault } from '../../StatsPage/formatters';

const VIEWS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  ALLTIME: 'ALLTIME',
};

const BUTTON_LABELS = {
  [VIEWS.DAY]: 'Last 24 hours',
  [VIEWS.WEEK]: '7 days',
  [VIEWS.MONTH]: '30 days',
  [VIEWS.ALLTIME]: 'All-time',
};

const TopSalesQuery = graphql`
  query TopSalesQuery {
    content: contentfulLandingPageV3 {
      ...TopSalesContent
    }
    data: contentfulLandingPageTopSales {
      ...TopSalesData
    }
  }
`;

const percentIncrease = (startValue, endValue) => {
  const cappedStart = Math.max(1, startValue);
  const delta = endValue - cappedStart;
  return Math.floor(100 * (delta / cappedStart));
};

const TopSales = () => {
  const { content, data } = useStaticQuery(TopSalesQuery);
  const [selectedView, setSelectedView] = React.useState(VIEWS.DAY);
  const [selectedData, setSelectedData] = React.useState([]);

  React.useEffect(() => {
    if (selectedView === VIEWS.DAY) {
      setSelectedData(data.last24h);
    } else if (selectedView === VIEWS.WEEK) {
      setSelectedData(data.last7d);
    } else if (selectedView === VIEWS.MONTH) {
      setSelectedData(data.last30d);
    } else if (selectedView === VIEWS.ALLTIME) {
      setSelectedData(data.allTime);
    }
  }, [selectedView]);

  return (
    <Frame heading="top sales" css={{ marginTop: '80px'}}>
      <FramePadding>
        <Controls>
          {Object.keys(VIEWS).map((view, index) => (
            <React.Fragment key={`top-sales-${view}-${index}`}>
              <ControlButton
                key={`top-sales-${view}-${index}`}
                isActive={selectedView === VIEWS[view]}
                onClick={() => setSelectedView(VIEWS[view])}>
                {BUTTON_LABELS[view]}
              </ControlButton>
              {index !== Object.keys(VIEWS).length - 1 ? <ControlDivider /> : null}
            </React.Fragment>
          ))}
          <ControlDivider />
          <ControlButton as={GatsbyLink} to="/stats">{/* This one disappears at sm breakpoint */}
            More Stats
          </ControlButton>
        </Controls>
        <ControlButtonSm as={GatsbyLink} to="/stats">{/* This one appears at sm breakpoint */}
          More Stats
        </ControlButtonSm>
        <table css={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <TableHead opacity={0}>Rank</TableHead>
              <TableHead opacity={0}>Name</TableHead>
              <TableHead>Sold for</TableHead>
              <TableHead md={'0%'}>Date sold</TableHead>
            </tr>
          </thead>
          <tbody>
            {selectedData.map((dataPoint, index) => {
              const salePrice = parseFloat(dataPoint.amount);
              const originalPrice = parseFloat(dataPoint.amount2);

              const rawGain = percentIncrease(originalPrice, salePrice);
              const formattedGain = `+ ${localeStringDefault(rawGain)}%`;
              const formattedSalePrice = parseFloat(salePrice).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              });

              return (
                <TableRow key={`top-sale-${dataPoint.domainName}-${index}`}>
                  <TableCell
                    css={{ fontFamily: 'Roboto Mono' }}
                    align="left"
                    weight="bold"
                    colored="nbPrimary">
                    {String(index + 1).padStart(2, '0')}
                  </TableCell>
                  <TableCell css={{ fontFamily: 'Roboto Mono' }} align="left" weight="bold">
                    <DomainNameAnchor href={`/domains/${dataPoint.domainName}`}>
                      {domainNameWithPunycode(dataPoint.domainName)}
                    </DomainNameAnchor>
                  </TableCell>
                  <TableCell align="right">
                    <SaleDetails soldFor={`${formattedSalePrice} HNS`} />
                  </TableCell>
                  <TableCell md="0%" align="right" colored="grey60">
                    {dataPoint.date}
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        </table>
        <CtaLg href="/stats">
          <Large>More Handshake Stats</Large>
        </CtaLg>
      </FramePadding>
    </Frame>
  );
};

export { TopSales };
