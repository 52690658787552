import styled, { css, keyframes } from 'styled-components';

const gridBackground = css`
  background-color: ${({ theme }) => theme.color.almostBlack};
  background-size: 120px 120px;
  background-position: -1px -1px;

  background-image: linear-gradient(to right, rgba(0, 104, 255, 0.45) 0.4px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 104, 255, 0.45) 0.4px, transparent 1px);
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${gridBackground}

  *:last-child {
    margin-bottom: 0;
  }

  padding-top: ${({ theme }) => theme.spacing(21)};
  padding-left: ${({ theme }) => theme.spacing(34.125)};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-top: ${({ theme }) => theme.spacing(17)};
    padding-left: ${({ theme }) => theme.spacing(20.125)};
    padding-right: ${({ theme }) => theme.spacing(13.125)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(10.626)};
    padding-right: ${({ theme }) => theme.spacing(10.625)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const QuoteWrapperFullWidth = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(16)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-bottom: ${({ theme }) => theme.spacing(12)};
  }
`;

const orchestrateLoadAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const reverseLoadAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15%);
  }
`;

export const QuoteContent = styled.blockquote`
  max-width: ${({ theme }) => theme.spacing(55.5)};
  margin-left: ${({ marginLeft }) => marginLeft};

  opacity: 0;
  transform: translateY(10%);

  animation: ${({ animate, reverseAnimate }) => {
    const playState = animate || reverseAnimate ? 'running' : 'paused';
    const animation = reverseAnimate ? reverseLoadAnimation : orchestrateLoadAnimation;
    return css`0.45s ease-out 0s 1 normal both ${playState} ${animation}`;
  }};

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 40%;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 100%;
    width 100%;
    margin-left: 0;
  }
`;

export const Quote = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.text.large.size};
  line-height: ${({ theme }) => theme.text.large.lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Author = styled.cite`
  font-style: normal;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.text.tiny.size};
  line-height: ${({ theme }) => theme.text.tiny.lineHeight};
  & > * {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
`;

export const BottomImgWrapper = styled.div`
  ${gridBackground}
  background-position: -1px 65px;
`;
