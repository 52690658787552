import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import { Divider } from '../Divider';
import { theme } from '../../../theme';

import {
  Content,
  Heading,
  LearnMoreLink,
  PhoneImage,
  Step,
  Steps,
  StepDescription,
  StepNumber,
  StepTitle,
  PhoneImageBreakpoints,
} from './HowItWorks.StyledComponents';

import config from '../../../config';

const HowItWorksQuery = graphql`
  query HowItWorksQuery {
    content: contentfulLandingPageV2 {
      ...HowItWorksContent
    }
    imageContent: contentfulLandingPageV2ImageAssets {
      ...HowItWorksRemoteImage
    }
    localImageContent: file(relativePath: { eq: "grey10WavyBottom.png" }) {
      ...HowItWorksLocalImage
    }
  }
`;

const HowItWorks = () => {
  const { content, imageContent, localImageContent } = useStaticQuery(HowItWorksQuery);

  return (
    <section css={{ backgroundColor: theme.color.white, maxWidth: '100vw' }}>
      <Content>
        <Heading>{content.howItWorksHeading}</Heading>
        <Divider />
        <Steps>
          <Step>
            <StepNumber>01</StepNumber>
            <StepTitle>{content.howItWorksStep1Heading}</StepTitle>
            <StepDescription>{content.howItWorksStep1Description}</StepDescription>
          </Step>
          <Step>
            <StepNumber>02</StepNumber>
            <StepTitle>{content.howItWorksStep2Heading}</StepTitle>
            <StepDescription>{content.howItWorksStep2Description}</StepDescription>
          </Step>
          <Step>
            <StepNumber>03</StepNumber>
            <StepTitle>{content.howItWorksStep3Heading}</StepTitle>
            <StepDescription>{content.howItWorksStep3Description}</StepDescription>
          </Step>
        </Steps>
        <LearnMoreLink
          label="Learn about the auction process"
          href={`${config.learnUrl}/about-handshake/handshake-auction`}
        />
      </Content>

      <Img
        css={{
          zIndex: 1,
          maxWidth: '100%',
        }}
        fadeIn={false}
        fluid={localImageContent.wavyBottom.fluid}
        // No alt on this, its part of the layout
        alt=""
      />

      <div
        css={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end',
          maxWidth: '100%',
        }}>
        <PhoneImage>
          <PhoneImageBreakpoints>
            <Img
              fluid={imageContent.isometricIPhone.fluid}
              alt={imageContent.isometricIPhoneAltText}
            />
          </PhoneImageBreakpoints>
        </PhoneImage>
      </div>
    </section>
  );
};

export { HowItWorks };
