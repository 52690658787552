import React, { useEffect, useState } from 'react';

import { get } from '../../../utils/requestFunctions';
import {Large, Regular, Small} from '../../text';

import { HandshakeLogoSvg } from './HandshakeLogoSvg';

import {
  BuyButton,
  Currency,
  Graph,
  HandshakeText,
  Prices,
  PriceStripAside,
} from './PriceStrip.StyledComponents';

const PRICE_LOADING_PLACEHOLDER = 'Loading...';

const PriceStrip = (props) => {
  const [graphMarkup, setGraphMarkup] = useState(null);
  const [btcPrice, setBtcPrice] = useState(null);
  const [btcUsdPrice, setBtcUsdPrice] = useState(null);
  const [usdPrice, setUsdPrice] = useState(null);

  useEffect(() => {
    get('/api/exchange/prices').then(res => {
      if (res.hnsBtcPrice) {
        setBtcPrice(res.hnsBtcPrice);
      }
      if (res.btcUsdPrice) {
        setBtcUsdPrice(res.btcUsdPrice);
      }
      if (res.hnsBtcPrice && res.btcUsdPrice) {
        setUsdPrice((parseFloat(res.hnsBtcPrice) * parseFloat(res.btcUsdPrice)).toFixed(2));
      }

    });

    fetch(`/api/v0/ticker/chart.svg?symbol=HNSBTC&token=${Math.random()}`)
      .then(res => res.text())
      .then(res => setGraphMarkup(res));
  }, []);

  return (
    <PriceStripAside {...props}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <HandshakeLogoSvg />
        <HandshakeText>
          <span>Handshake</span>&nbsp;HNS
        </HandshakeText>
      </div>
      <Prices>
        <div>
          {btcPrice ? (
            <Large as="span">{btcPrice}</Large>
          ) : (
            <Regular as="span" css={{ color: '#aaa' }}>{PRICE_LOADING_PLACEHOLDER}</Regular>
          )}
          <Currency as="span">BTC</Currency>
        </div>
        <div>
          {usdPrice ? (
            <Large as="span">{usdPrice}</Large>
          ) : (
            <Regular as="span" css={{ color: '#aaa' }}>{PRICE_LOADING_PLACEHOLDER}</Regular>
          )}
          <Currency as="span">USD</Currency>
        </div>
        <Graph dangerouslySetInnerHTML={{ __html: graphMarkup }} />
        <BuyButton>Buy</BuyButton>
      </Prices>
    </PriceStripAside>
  );
};

export { PriceStrip };
