import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Large } from '../../text';
import { TweetEmbedLookalike } from './components/TweetEmbedLookalike';
import {
  TweetReel,
  ReelItem,
  TweetReelOverflow,
  CommunitySection,
  CommunityCta
} from './TheCommunity.StyledComponents';
import {Cta} from "../LandingPage.StyledComponents";

const TheCommunityQuery = graphql`
  query TheCommunityQuery {
    content: contentfulLandingPageV3 {
      ...TheCommunityContent
    }
  }
`;

const TheCommunity = () => {
  const { content } = useStaticQuery(TheCommunityQuery);

  return (
    <CommunitySection>
      <CommunityCta>
        <a href={content.communityCtaHref} target="_blank">
          <Large as="h2">{content.communityCtaLabel}</Large>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.6665 28.3333L30.9999 20L22.6665 11.6667"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.1984 20.0583L9.83313 20.0583"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </CommunityCta>
      <TweetReel>
        {content.tweets.map((tweet, index) => (
          <ReelItem className="communityTweet" key={tweet.id} css={{ gridArea: `tweet${index}` }}>
            <TweetEmbedLookalike {...tweet} />
          </ReelItem>
        ))}
      </TweetReel>
    </CommunitySection>
  );
};

export { TheCommunity };
