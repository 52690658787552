import styled from 'styled-components';

export const FinalCtaSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.color.white};

  background-color: ${({ theme }) => theme.color.white};
  padding-top: ${({ theme }) => theme.spacing(21)};
  padding-bottom: ${({ theme }) => theme.spacing(21)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: ${({ theme: { spacing } }) => `${spacing(13)} ${spacing(8)}`};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.spacing(98)};
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h2`
  color: ${({ theme }) => theme.color.brandDark};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.displayLarge.size};
    line-height: ${({ theme }) => theme.text.displayLarge.lineHeight};
  }
`;

export const SubHeading = styled.h3`
  color: ${({ theme }) => theme.color.brandDark};
  font-size: ${({ theme }) => theme.text.header.size};
  line-height: ${({ theme }) => theme.text.header.lineHeight};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(12.5)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.huge.size};
    line-height: ${({ theme }) => theme.text.huge.lineHeight};
  }
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background: ${props => props.theme.color.white};
  font-size: ${props => props.theme.text.headerLarge.size};
  font-weight: 700;
  caret-color: ${props => props.theme.color.nbPrimary};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  &::placeholder {
    font-size: inherit;
    color: ${props => props.theme.color.grey50};
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: ${props => props.theme.text.large.size};
  }
`;

export const Rule = styled.hr`
  height: 1px;
  width: 100%;
  border: 0;
  background: ${({ theme }) => theme.color.grey30};
`;
