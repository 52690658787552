import React from 'react';

import { Footer } from '../footer';
import * as Sections from './sections';
import { Section } from './LandingPage.StyledComponents';
import { PriceStrip } from '../LandingPage';
import { PriceSection } from '../LandingPage/PriceStrip/PriceStrip.StyledComponents';

const LandingPage = () => (
  <React.Fragment>
    {/*<Section>
      <Sections.Banner />
    </Section>*/}
    <Section>
      <Sections.Hero />
    </Section>
    <Section>
      <PriceSection>
        <PriceStrip />
      </PriceSection>
    </Section>
    <Section autoLayout={true} style={{ position: 'relative', overflow: 'hidden' }}>
      <Sections.HowNamebaseWorks />
    </Section>
    <Section autoLayout={true}>
      <Sections.TopSales />
    </Section>
    <Section autoLayout={true}>
      <Sections.DecentralizedDns />
    </Section>
    <Section autoLayout={true}>
      <Sections.BackersAndAdopters />
    </Section>
    <Section autoLayout={true}>
      <Sections.TheCommunity />
    </Section>
    <Section as="div" autoLayout={true}>
      <Sections.Marketplace />
    </Section>
    <Footer />
  </React.Fragment>
);

export { LandingPage };
